<template>
  <div class="home-attendance-container">
    <div class="top-contain">
      <div class="title">{{$t('考勤签到')}}</div>
      <en-icon class="arrow" size="12" name="xiayiye" @click.native="recordAction"></en-icon>
    </div>
    <div class="center-contain">
      <en-icon
        class="sign-icon"
        size="29"
        name="kaoqin"
        @click.native="signInAction"
      ></en-icon>
    </div>
    <div class="bottom-contain">
      <div v-if="homeShowTime.length > 0" class="circle" :class="{abnormal: homeIsAbNormal}"
      ></div>
      <div v-if="homeShowTime.length > 0" class="time">{{ homeShowTime }}</div>
    </div>

    <!--考勤打卡二次确认弹框-->
    <attConfirmDialog
      v-if="showConfirmDialog"
      :dialog-visible.sync="showConfirmDialog"
      :tipsText="showConfirmMessage"
      :abnormal="true"
      @getComfirmValue="getComfirmValue"
      @closeAction="signInReset"
    ></attConfirmDialog>

    <!--考勤必填说明弹框-->
    <attDescriptionDialog
      ref="attDescriptionDialog"
      v-if="showDescriptionDialog"
      :signStrategyData="attStrategyData"
      :signInfoData="signInfo"
      :dialog-visible.sync="showDescriptionDialog"
      @confirmWithInputValue="descriptionConfirmWithInputValue"
      @closeAction="signInReset"
    ></attDescriptionDialog>

    <businessDialog
      v-if="businessDialog"
      :businessParams="businessParams"
      :dialogVisible.sync="businessDialog">
    </businessDialog>
  </div>
</template>

<script>
import { enEqual } from "@/tools/compare";
import businessDialog from "@/components/businessDetail/businessDialog";
import { newBusinessDialogParams } from "@/components/businessDetail/helper.js";
import attConfirmDialog from "../dialog/attConfirmDialog";
import attDescriptionDialog from "../dialog/attDescriptionDialog";
import signInData from "../signInData";

export default {
  name: "HomeAttendance",
  components: {
    attConfirmDialog,
    attDescriptionDialog,
    businessDialog
  },
  data() {
    return {
      showConfirmDialog: false,
      showConfirmMessage: "",
      showDescriptionDialog: false,
      signStrategyData: {},
      signInfo: {
        recordTime: "",
        ipAddress: "",
        confirmFlag: "1",
        isModify: "1",
        isRemark: "1",
        isPicture: "1",
        remark: "",
        attachment: "",
        relations: {},
        status: -1
      },
      businessDialog: false,
      businessParams: {}
    };
  },
  created() {
    this.requestAttConfig();
    this.requestRecordDataList({});
  },
  watch: {
    signInData() {
      this.signInRspHandle();
    }
  },
  mounted() {
    this.signStrategyData = this.attStrategyData;
  },
  mixins: [signInData],
  methods: {
    // 打卡按钮事件
    signInAction() {
      this.requestSignIn(this.compareSignInQueryData());
    },
    // 处理打卡参数
    compareSignInQueryData() {
      const params = {};
      params.isModify = this.attStrategyData.isModify === true ? "0" : "1";
      params.isRemark = this.attStrategyData.isRemark === true ? "0" : "1";
      params.isPicture = this.attStrategyData.isPicture === true ? "0" : "1";
      if (enEqual(this.signInfo.confirmFlag, 0)) {
        params.confirmFlag = "0";
      }
      params.remark = this.signInfo.remark;
      const attachmentList = [];
      if (this.signInfo.attachment) {
        for (let i = 0; i < this.signInfo.attachment.length; i++) {
          const tmp = this.signInfo.attachment[i];
          const obj = {
            name: tmp.name,
            suffix: tmp.extension,
            fileSize: tmp.size,
            url: tmp.code,
            fileType: tmp.dataType
          };
          attachmentList.push(obj);
        }
      }
      params.attachment = JSON.stringify(attachmentList);
      const relationList = [];
      if (this.signInfo.relations) {
        for (let i = 0; i < this.signInfo.relations.length; i++) {
          const tmp = this.signInfo.relations[i];
          const obj = {
            objectTypeId: tmp.objectTypeId,
            dataId: tmp.selectValue.id,
            dataName: tmp.selectValue.name,
            objectDataType: tmp.dataType
          };
          relationList.push(obj);
        }
      }
      params.relations = JSON.stringify(relationList);
      return params;
    },
    // 打卡后重置数据
    signInReset() {
      this.signInfo = {
        recordTime: "",
        ipAddress: "",
        confirmFlag: "1",
        isModify: "1",
        isRemark: "1",
        isPicture: "1",
        remark: "",
        attachment: "",
        relations: {},
        status: -1
      };
      this.requestRecordDataList({});
    },
    // 跳转考勤记录页面
    recordAction() {
      this.$router.push("/hr/attendance");
    },
    // 再次确认打卡
    getComfirmValue() {
      this.signInfo.confirmFlag = "0";
      this.requestSignIn(this.compareSignInQueryData());
    },
    // 弹框打卡确认
    descriptionConfirmWithInputValue(dataInfo) {
      this.signInfo.remark = dataInfo.remark;
      this.signInfo.attachment = [...dataInfo.picList];
      this.signInfo.relations = [...dataInfo.projectList];
      this.requestSignIn(this.compareSignInQueryData());
    },
    // 打卡结果逻辑处理
    signInRspHandle() {
      const result = this.signInData.result;
      this.signInfo.status = this.signInData.status;
      // 打卡成功
      if (enEqual(result, 0)) {
        const status = this.signInData.status.toString();
        this.signInWithStatus(status, enEqual(this.signInfo.confirmFlag, 0));
      } else if (enEqual(result, 1)) {
        // 打卡失败
        this.$message.error("签到失败，请检查网络后重试");
      } else if (enEqual(result, 2) || enEqual(result, 3)) {
        // 有必填信息 例外打卡
        this.signInfo.recordTime = this.signInData.recordTime;
        this.signInfo.ipAddress = this.signInData.ipAddress;
        this.showDescriptionDialog = true;
      }
    },
    // 打卡过程状态逻辑处理
    signInWithStatus(status, isConfirm) {
      console.log(status, isConfirm);
      switch (status) {
        case "1":
        case "7":
          if (this.$refs.attDescriptionDialog) {
            this.$refs.attDescriptionDialog.closeClick();
          }
          this.$message({
            message: "签到成功，又是新的一天，加油哦~",
            type: "success"
          });
          this.signInReset();
          break;
        case "2":
        case "3":
        case "11":
        case "12":
        case "13":
          if (isConfirm) {
            if (
              enEqual(this.signInData.attAutoDealBill, 0)
              && this.attModelData.formId.length > 0
              && enEqual(this.signInData.isValidRecord, 0)
              && status !== "13"
            ) {
              if (this.$refs.attDescriptionDialog) {
                this.$refs.attDescriptionDialog.closeClick();
              }
              // 填写异常表单
              this.$message({
                message: "签到成功!",
                type: "success"
              });
              this.signInReset();
              if (this.signInData?.attAutoDealBill === "0"
                && this.signInData?.isValidRecord === 0
                && this.signInData?.formId.length > 0) {
                this.businessDialogCick(this.signInData.formId, this.signInData.formType);
              }
            } else {
              if (this.$refs.attDescriptionDialog) {
                this.$refs.attDescriptionDialog.closeClick();
              }
              this.$message({
                message: "签到成功，又是新的一天，加油哦~",
                type: "success"
              });
              this.signInReset();
            }
          } else {
            let message = "";
            if (status === "2") {
              message = "当前打卡为迟到，是否继续？";
            } else if (status === "3") {
              message = "当前打卡为早退，是否继续？";
            } else if (status === "12") {
              message = "当前打卡为旷工，是否继续？";
            } else if (status === "13") {
              message = "当前不属于有效打卡时间范围，是否仍要打卡？";
            } else {
              message = "当前打卡为异常,是否继续";
            }
            this.showConfirmMessage = message;
            this.showConfirmDialog = true;
          }
          break;
        case "4": // 不在GPS范围内
          this.$message.error("当前不在指定考勤区域");
          break;
        case "5": // 不在WIFI范围内
          this.$message.error("当前没有连接到指定网络,请连接到指定WiFi");
          break;
        case "6": // 不在允许打卡的时间范围内
          this.$message.error("当前不在允许打卡的时间范围内");
          break;
        case "8":
          this.$message.error("不在指定IP段");
          break;
        case "9":
          this.$message.error("已经签退");
          break;
        case "10":
          this.$message.error("当前配置不能打卡");
          break;
      }
    },

    // 跳转业务建模
    businessDialogCick(formId, formType) {
      // console.log(this.attModelData);
      const params = {
        templateId: formId,
        businessType: formType
      };
      this.businessParams = newBusinessDialogParams(params);
      this.businessDialog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.home-attendance-container {
  background: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #e8ecf2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .top-contain {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #e8ecf2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      padding-left: 20px;
    }
    .arrow {
      color: #a9b5c6;
      margin-right: 20px;
    }
  }
  .center-contain {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3e90fe;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    .sign-icon {
      color: #ffffff;
    }
  }
  .bottom-contain {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    .circle {
      width: 7px;
      height: 7px;
      background-color: #26c393;
      border-radius: 50%;
      &.abnormal {
        background-color: #f76b6b;
      }
    }
    .time {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: #636c78;
      padding-left: 6px;
    }
  }
}
</style>
