var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home-attendance-container" },
    [
      _c(
        "div",
        { staticClass: "top-contain" },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("考勤签到"))),
          ]),
          _c("en-icon", {
            staticClass: "arrow",
            attrs: { size: "12", name: "xiayiye" },
            nativeOn: {
              click: function ($event) {
                return _vm.recordAction.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "center-contain" },
        [
          _c("en-icon", {
            staticClass: "sign-icon",
            attrs: { size: "29", name: "kaoqin" },
            nativeOn: {
              click: function ($event) {
                return _vm.signInAction.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "bottom-contain" }, [
        _vm.homeShowTime.length > 0
          ? _c("div", {
              staticClass: "circle",
              class: { abnormal: _vm.homeIsAbNormal },
            })
          : _vm._e(),
        _vm.homeShowTime.length > 0
          ? _c("div", { staticClass: "time" }, [
              _vm._v(_vm._s(_vm.homeShowTime)),
            ])
          : _vm._e(),
      ]),
      _vm.showConfirmDialog
        ? _c("attConfirmDialog", {
            attrs: {
              "dialog-visible": _vm.showConfirmDialog,
              tipsText: _vm.showConfirmMessage,
              abnormal: true,
            },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.showConfirmDialog = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.showConfirmDialog = $event
              },
              getComfirmValue: _vm.getComfirmValue,
              closeAction: _vm.signInReset,
            },
          })
        : _vm._e(),
      _vm.showDescriptionDialog
        ? _c("attDescriptionDialog", {
            ref: "attDescriptionDialog",
            attrs: {
              signStrategyData: _vm.attStrategyData,
              signInfoData: _vm.signInfo,
              "dialog-visible": _vm.showDescriptionDialog,
            },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.showDescriptionDialog = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.showDescriptionDialog = $event
              },
              confirmWithInputValue: _vm.descriptionConfirmWithInputValue,
              closeAction: _vm.signInReset,
            },
          })
        : _vm._e(),
      _vm.businessDialog
        ? _c("businessDialog", {
            attrs: {
              businessParams: _vm.businessParams,
              dialogVisible: _vm.businessDialog,
            },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.businessDialog = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.businessDialog = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }