var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "my-dialog",
      attrs: {
        "append-to-body": "",
        title: "",
        "show-close": false,
        visible: _vm.dialogComfirmVisible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogComfirmVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "body-contain" },
        [
          _c(
            "div",
            { on: { click: _vm.closeClickAction } },
            [
              _c("en-icon", {
                staticClass: "close-icon",
                style: { fill: "#A9B5C6" },
                attrs: { name: "guanbi-danchuang", size: "14px" },
              }),
            ],
            1
          ),
          _c("en-icon", {
            staticClass: "tips-icon",
            class: { abnormal: _vm.abnormal },
            attrs: { name: _vm.abnormal ? "tishi" : "xiaoxi", size: "30px" },
          }),
          _c("div", { staticClass: "tips-text" }, [
            _vm._v(" " + _vm._s(_vm.tipsText) + " "),
          ]),
          _c(
            "en-button",
            {
              staticClass: "button",
              attrs: { type: _vm.abnormal ? "red" : "primary" },
              on: { click: _vm.confirmClick },
            },
            [_vm._v(" 确定 ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }