<template>
  <el-dialog class="my-dialog" append-to-body title="" :show-close="false" :visible.sync="dialogComfirmVisible">
    <div class="body-contain">
      <div @click="closeClickAction">
        <en-icon
          name="guanbi-danchuang"
          class="close-icon"
          size="14px"
          :style="{ fill: '#A9B5C6' }"
        ></en-icon>
      </div>

      <en-icon
        :name="abnormal ? 'tishi' : 'xiaoxi'"
        class="tips-icon"
        size="30px"
        :class="{ abnormal: abnormal }"
      ></en-icon>

      <div class="tips-text">
        {{ tipsText }}
      </div>

      <en-button
        class="button"
        :type="abnormal ? 'red' : 'primary'"
        @click="confirmClick"
      >
        确定
      </en-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "AlertDialog",
  data() {
    return {
      dialogComfirmVisible: true
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    },
    abnormal: {
      type: Boolean,
      default: false
    },
    tipsText: {
      type: String,
      default: ""
    }
  },
  watch: {
    dialogComfirmVisible(val) {
      this.$emit("update:dialogVisible", val);
    }
  },
  methods: {
    closeClickAction() {
      this.dialogComfirmVisible = false;
      this.$emit("closeAction");
    },
    closeClick() {
      this.dialogComfirmVisible = false;
    },
    confirmClick() {
      this.$emit("getComfirmValue");
      this.closeClick();
    }
  }
};
</script>

<style lang="scss" scoped>
.body-contain {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 500px;
  height: 160px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 4px;

  .title {
    position: absolute;
    left: 20px;
    top: 20px;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    font-family: MicrosoftYaHei;
    font-weight: bold;
    text-align: left;
    color: #333;
  }
  .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 14px;
    height: 14px;
  }
  .tips-icon {
    position: absolute;
    left: 32px;
    top: 42px;
    color: #3e90fe;
    &.abnormal {
      color: #f76b6b;
    }
  }
  .tips-text {
    position: absolute;
    left: 76px;
    top: 50px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    font-weight: bold;
    color: #333;
  }
  .button {
    position: absolute;
    right: 20px;
    bottom: 16px;
    width: 72px;
    height: 32px;
  }
}
.my-dialog {
  & /deep/ .el-dialog__header {
    padding: 0;
    margin: 0;
    height: 0;
  }
  & /deep/ .el-dialog__body {
    padding: 0;
    margin: 0;
  }
}
</style>
